/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useMemo, useReducer } from 'react';
import { PlayerContext } from '../../../state/context';
import { initialState, reducer } from '../../../state/reducer';
import { Actions } from '../../../state/action';

const audio = new Audio();
if (typeof window !== 'undefined') {
  window.audioElement = audio;
}

function AudioPlayerProvider({ children }) {
  const [
    {
      audioUrl,
      firstLoad,
      loading,
      error,
      playing,
      stopped,
      position,
      duration,
      playbackRate,
      ready,
      ended,
      name,
      episodeId,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const setName = (name) => {
    dispatch({ type: Actions.ON_SET_NAME, name });
  };

  const setAudioUrl = (audioUrl) => {
    dispatch({ type: Actions.ON_SET_AUDIO_URL, audioUrl });
  };

  const setEpisodeId = (id) => {
    dispatch({ type: Actions.ON_SET_EPISODE_ID, id });
  };

  const onCanPlay = () => {
    dispatch({ type: Actions.ON_CAN_PLAY });
  };

  const onDurationChange = () => {
    dispatch({ type: Actions.ON_DURATION_CHANGED, duration: audio.duration });
  };

  const onEnded = () => {
    dispatch({ type: Actions.ON_END });
  };

  const onLoadStart = () => {
    dispatch({ type: Actions.START_LOAD });
  };

  const onPause = () => {
    dispatch({ type: Actions.ON_PAUSE });
  };

  const onPlaying = () => {
    dispatch({ type: Actions.ON_PLAYING });
  };

  const onRateChange = () => {
    dispatch({ type: Actions.ON_RATE_CHANGE, playbackRate: audio.playbackRate });
  };

  const onTimeUpdate = () => {
    dispatch({ type: Actions.ON_TIME_UPDATE, position: audio.currentTime });
  };

  const initialiseAudio = () => {
    audio.oncanplay = onCanPlay;
    audio.ondurationchange = onDurationChange;
    audio.onended = onEnded;
    audio.onloadstart = onLoadStart;
    audio.onpause = onPause;
    audio.onplaying = onPlaying;
    audio.onratechange = onRateChange;
    audio.ontimeupdate = onTimeUpdate;
    audio.preload = 'none';
  };

  useEffect(() => {
    initialiseAudio();
  }, []);

  const contextValue = useMemo(() => {
    return {
      audio,
      audioUrl,
      duration,
      ended,
      episodeId,
      error,
      firstLoad,
      loading,
      name,
      playbackRate,
      playing,
      position,
      ready,
      setAudioUrl,
      setEpisodeId,
      setName,
      stopped,
    };
  }, [
    audioUrl,
    duration,
    ended,
    episodeId,
    error,
    firstLoad,
    loading,
    name,
    playbackRate,
    playing,
    position,
    ready,
    setAudioUrl,
    setEpisodeId,
    setName,
    stopped,
  ]);

  return <PlayerContext.Provider value={contextValue}>{children}</PlayerContext.Provider>;
}

export default AudioPlayerProvider;

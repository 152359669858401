export default function getMyRadioEpisode(show = {}, clip = {}) {
  const { Id, Title, Slug, AudioUrl, ImageUrl, Description, DurationSeconds, PublishedUtc } = clip;
  const { Name, ArtworkUrl } = show;

  // restructure the episode data in sunch a way so that it has similar data pattern as same as listnr episode
  const episode = {
    id: Id,
    title: Title,
    slug: Slug,
    audioUrl: AudioUrl,
    imageUrl: ImageUrl,
    description: Description,
    durationSeconds: DurationSeconds,
    publishedUtc: PublishedUtc,
    show: {
      name: Name,
      omnySmallArtworkUrl: ArtworkUrl,
    },
  };
  return episode;
}

import { Suspense, useEffect, useState } from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';

export default function Import({ mobile, tablet, desktop }) {
  const windowSize = useWindowSize();
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    if (windowSize === 'mobile') {
      setComponent(mobile);
      return;
    }
    if (windowSize === 'tablet') {
      setComponent(tablet);
      return;
    }
    setComponent(desktop);
  }, [desktop, tablet, mobile, windowSize]);

  return <Suspense fallback={<div>Loading...</div>}>{Component}</Suspense>;
}

import './style.css';
import useOmnyConsumptionAnalytics from '../../../hooks/useOmnyConsumptionAnalytics';
import usePrevious from '../../../hooks/usePrevious';
import { IFRAME_MESSAGE_TYPES } from '../../../utilities/helpers/iframeMessageTypes';
import Background from '../Background';
import Footer from '../Footer';
import Import from '../Import';
import { lazy } from 'react';
import { useAudioPlayer } from '../../../hooks/useAudioPlayer';
import { useEffect, useState } from 'react';
import NoContent from '../NoContent';
import api from '../../../utilities/api';

const PlayerDesktop = lazy(() => import('../../desktop/PlayerDesktop'));
const PlayerTablet = lazy(() => import('../../tablet/PlayerTablet'));
const PlayerMobile = lazy(() => import('../../mobile/PlayerMobile'));

const Player = ({ playlistId }) => {
  const { changeTrack, ended, setAudioSrc, pause, setName, position, playing, episodeId } = useAudioPlayer();
  const [error, setError] = useState(false);
  const [activeEp, setActiveEp] = useState({});
  const [showImage, setShowImage] = useState('');
  const [clips, setClips] = useState([]);
  const [show, setShow] = useState({});
  const [isListnrShow, setListnrShow] = useState({});

  const prevCurrentTime = usePrevious(position);
  const prevAudioPlaying = usePrevious(playing);
  const prevEpisodeId = usePrevious(episodeId);
  useOmnyConsumptionAnalytics({
    prevCurrentTime,
    currentTime: position,
    prevEpisodeId,
    episodeId,
    prevAudioPlaying,
    audioPlaying: playing,
    isListnrShow,
  });

  const handleIframeMessage = (event) => {
    if (event.data?.type === IFRAME_MESSAGE_TYPES.registerNewPlayer) {
      setName(event?.data?.name);
    }
    if (event.data?.type === IFRAME_MESSAGE_TYPES.pausePlayer) {
      pause();
    }
  };

  useEffect(() => {
    // iFrame message received from MyRadio
    window.addEventListener('message', handleIframeMessage, false);
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [show, isListnrShow] = await api.getShow(playlistId);
        if (!show) {
          // eslint-disable-next-line no-console
          console.error('Show not found');
          setError(true);
          return;
        }

        setListnrShow(isListnrShow);
        setClips(show?.episodes?.items);
        setShow(show);
        setShowImage(show?.images?.square?.url || show?.omnySmallArtworkUrl);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setError(true);
      }
    };
    fetchData();
  }, [playlistId]);

  useEffect(() => {
    const clip = clips && clips[0];
    if (clip) {
      setAudioSrc(clip.audioUrl, clip.id);
      clip.index = 0;
      setActiveEp(clip);
    }
  }, [clips, setAudioSrc]);

  useEffect(() => {
    if (ended) {
      handleChangeTrack(activeEp.index + 1);
    }
  }, [ended]);

  const handleChangeTrack = (index) => {
    if (index < 5) {
      const clip = clips[index];
      clip.index = index;
      setActiveEp(clip);
      changeTrack(clip.audioUrl, clip.id);
    }
  };

  if (error) {
    return <NoContent />;
  }

  return (
    <div className="player-root">
      <div className="player-content">
        <Background imageUrl={showImage} />
        <div className="player-foreground">
          <div className="player-gutter">
            <Import
              mobile={
                <PlayerMobile
                  activeEp={activeEp}
                  clips={clips}
                  handleTrackClick={handleChangeTrack}
                  listnrShow={isListnrShow}
                  showImage={showImage}
                  showName={show.name}
                  showRssUrl={show.rssFeedUrl}
                  showSlug={show.slug}
                  showId={show.id}
                />
              }
              tablet={
                <PlayerTablet
                  activeEp={activeEp}
                  clips={clips}
                  handleTrackClick={handleChangeTrack}
                  listnrShow={isListnrShow}
                  showImage={showImage}
                  showName={show.name}
                  showRssUrl={show.rssFeedUrl}
                  showSlug={show.slug}
                  showId={show.id}
                />
              }
              desktop={
                <PlayerDesktop
                  activeEp={activeEp}
                  clips={clips}
                  handleTrackClick={handleChangeTrack}
                  listnrShow={isListnrShow}
                  showImage={showImage}
                  showName={show.name}
                  showRssUrl={show.rssFeedUrl}
                  showSlug={show.slug}
                  showId={show.id}
                />
              }
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Player;

import AudioPlayerProvider from './components/common/AudioPlayerProvider';
import Player from './components/common/Player';
import { Route } from 'wouter';
import SingleClipPlayer from './components/common/SingleClipPlayer';
// import { useEffect } from 'react';
// import { downloadLatestEpisode } from './utilities/helpers/downloadLatestEpisode';

function App() {
  // useEffect(() => {
  //   downloadLatestEpisode('its-a-lot-with-abbie-chatfield');
  // }, []);

  return (
    <AudioPlayerProvider>
      <Route path="/playlists/:playlistId">{(params) => <Player playlistId={params.playlistId} />}</Route>
      <Route path="/clips/:clipId">{(params) => <SingleClipPlayer clipId={params.clipId} />}</Route>
    </AudioPlayerProvider>
  );
}

export default App;

import { useState, useEffect } from 'react';
// Hook
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState('mobile');

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (window.matchMedia('(max-width: 430px)').matches) {
        setWindowSize('mobile');
        return;
      }
      if (window.matchMedia('(max-width: 670px)').matches) {
        setWindowSize('tablet');
        return;
      }
      setWindowSize('desktop');
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default function getMyRadioPlaylists(show = {}, clips = []) {
  const episodes =
    clips.length > 0 &&
    clips.map((data) => {
      const { Id, Title, Slug, Description, Season, Episode, ImageUrl, AudioUrl, DurationSeconds, PublishedUtc } = data;
      const items = {
        id: Id,
        title: Title,
        slug: Slug,
        audioUrl: AudioUrl,
        imageUrl: ImageUrl,
        description: Description,
        durationSeconds: DurationSeconds,
        publishedUtc: PublishedUtc,
        episode: Episode,
        season: Season,
      };
      return items;
    });

  const { Id, Slug, Name, Description, ArtworkUrl } = show;
  // restructure the show data in sunch a way so that it has similar data pattern as same as listnr show
  const showItem = {
    id: Id,
    slug: Slug,
    name: Name,
    showType: '',
    contentType: '',
    rssFeedUrl: '',
    description: Description,
    images: {
      square: {
        url: ArtworkUrl,
      },
    },
    episodes: {
      totalItems: clips?.length,
      currentPage: 0,
      items: episodes,
    },
  };
  return showItem;
}

import './style.css';

const Background = ({ imageUrl }) => (
  <div
    className="background"
    style={{
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(38,36,37,0.25) 100%), url(${imageUrl})`,
    }}
  />
);

export default Background;

import './style.css';
import Footer from '../Footer';
import { ReactComponent as NoPodcastIcon } from './no-podcast.svg';

const NoContent = ({ singleClip = false }) => {
  const flexDirection = singleClip ? 'row' : 'column';
  const fontSize = singleClip ? 14 : 18;
  return (
    <div className="no-content">
      <div className="no-content-wrapper" style={{ flexDirection }}>
        <NoPodcastIcon className="no-content-icon" />
        <h1 style={{ fontSize }}>Sorry, this content is no longer available</h1>
      </div>
      <Footer withBorder={false} />
    </div>
  );
};

export default NoContent;

import { useCallback, useContext } from 'react';
import { PlayerContext } from '../state/context';
import { changePlaybackRate } from '../utilities/helpers';
import { IFRAME_MESSAGE_TYPES } from '../utilities/helpers/iframeMessageTypes';
import { getDecoratedAudioUrl } from '../utilities/helpers/getDecoratedAudioUrl';
const noop = () => {};

export const useAudioPlayer = () => {
  const {
    audio,
    audioUrl,
    duration,
    ended,
    episodeId,
    firstLoad,
    loading,
    name,
    playbackRate,
    playing,
    ready,
    setAudioUrl,
    setEpisodeId,
    setName,
  } = useContext(PlayerContext);

  const togglePlayPause = useCallback(() => {
    if (!audio) return;

    if (audio.playing()) {
      audio.pause();
    } else {
      audio.play();
    }
  }, [audio]);

  const changeRate = useCallback(() => {
    if (!audio) return;

    audio.playbackRate = changePlaybackRate(playbackRate);
  }, [audio, playbackRate]);

  const setAudioSrc = useCallback(
    (url) => {
      setAudioUrl(url);
    },
    [audio]
  );

  const load = useCallback(() => {
    const decoratedAudioUrl = getDecoratedAudioUrl(audioUrl);
    audio.src = decoratedAudioUrl;
  }, [audio, audioUrl]);

  const changeTrack = useCallback(
    (sourceUrl, episodeId) => {
      const decoratedAudioUrl = getDecoratedAudioUrl(sourceUrl);

      if (decoratedAudioUrl !== audio.src) {
        audio.currentTime = 0;
        audio.src = decoratedAudioUrl;
        audio.play();
        setEpisodeId(episodeId);
      }
    },
    [audio]
  );

  const seek = useCallback((time) => {
    if (time >= 0 && time <= audio.duration) {
      audio.currentTime = time;
    }
  }, []);

  return {
    changeRate,
    changeTrack,
    duration,
    ended,
    load,
    loading,
    pause: audio ? audio.pause.bind(audio) : noop,
    play: audio
      ? () => {
          window.parent.postMessage({ type: IFRAME_MESSAGE_TYPES.pressPlay, name }, '*');
          if (firstLoad) {
            load();
          }
          audio.play();
        }
      : noop,
    playbackRate,
    playing,
    position: audio?.currentTime || 0,
    ready,
    seek,
    togglePlayPause,
    setName,
    setAudioSrc,
    episodeId,
  };
};

import { gql } from '@apollo/client';
import { getApolloClient } from './client';

export const executeApolloQuery = async (query, variables, usePOST) => {
  const client = getApolloClient();

  const res = await client.query({
    query: gql`
      ${query}
    `,
    variables,
  });
  return res.data;
};

export function fetchAPI(query, variables = {}, usePOST = false) {
  return executeApolloQuery(query, variables, usePOST);
}

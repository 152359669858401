const API = `https://omny.fm/api/orgs/${process.env.REACT_APP_OMNY_MY_RADIO_ORGANISATION_ID}/`;

async function fetchAPI(endpoint, cacheStrategy = 'default') {
  const url = `${API}${endpoint}`;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: cacheStrategy,
  });
  if (res.ok) {
    return await res.json();
  }
  return res;
}

export async function getClip(clipId) {
  try {
    return fetchAPI(`clips/${clipId}/`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return error;
  }
}

export async function getProgram(programId) {
  try {
    return fetchAPI(`programs/${programId}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return error;
  }
}

export async function getClips(playlistId) {
  const numberOfClips = 5;
  try {
    // return latest 'numberOfClips' clips
    return await fetchAPI(`playlists/${playlistId}/clips?pageSize=${numberOfClips}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return error;
  }
}

const omnyApi = {
  getClip,
  getProgram,
  getClips,
};

export default omnyApi;

import './style.css';
import ListnrLink from '../ListnrLink';

const Footer = ({ withBorder = true }) => {
  const borderCSS = withBorder ? { boxShadow: '0 0 20px 0 #000000' } : null;
  return (
    <footer className="footer" style={borderCSS}>
      <ListnrLink />
    </footer>
  );
};

export default Footer;

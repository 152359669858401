import listnrApi from './listnrApi';
import omnyApi from './omnyApi';
import getMyRadioPlaylists from '../helpers/getMyRadioPlaylists';
import getMyRadioEpisode from '../helpers/getMyRadioEpisode';
import get from 'lodash/get';

/**
 * @method getShow
 * @description: This function returns a show with latest five related episodes as playlists.
 * The Show details either could come from squidex via graphApi or in case the show is not available in squidex, it fetches show details from omny.
 * @param {string} { playlistId }: playlist id of a show.
 * @returns {array} [show, true/false]: return a show with relevant episodes and a flag which indicates whether it is listnr(true) or myRadio(false) show.
 */
async function getShow(playlistId) {
  let isListnrShow;
  const listnrShow = await listnrApi.getShow(playlistId);

  // if the show is not found in squidex/graph api
  if (!listnrShow?.show) {
    // get playlists from omny
    const playlistsResult = await omnyApi.getClips(playlistId);
    // extract show or program id from omny playlists. All playlists episodes have same program id supposedly.
    const programId = playlistsResult?.Clips[0]?.ProgramId;
    // get related show details for the playlists
    const show = await omnyApi.getProgram(programId);

    // restructure the playlists data in sunch a way so that it has similar data pattern as same as listnr episode
    const clips = playlistsResult?.Clips;
    const myRadioPlaylists = getMyRadioPlaylists(show, clips);
    isListnrShow = false;
    return [myRadioPlaylists, isListnrShow];
  }

  isListnrShow = true;
  return [listnrShow?.show, isListnrShow];
}

/**
 * @method getEpisode
 * @description: This function returns an episode.
 * The episode either could come from squidex via graphApi or in case the show is not available in squidex, it fetches episode from omny.
 * @param {string} { clipId }: clip id of a show.
 * @returns {array} [episode, true/false]: return an episode and a flag(true or false) which indicates whether it is listnr(true) or myRadio(false) episode.
 */
async function getEpisode(clipId) {
  let isListnrShow;
  const listnrEpisode = await listnrApi.getEpisode(clipId);

  // if the episode is not found in squidex/graph api
  if (!listnrEpisode?.episode) {
    // get episode details from omny based on clipId
    const clip = await omnyApi.getClip(clipId);
    // get related show details for the episode
    const show = await omnyApi.getProgram(clip.ProgramId);

    const myRadioEpisode = getMyRadioEpisode(show, clip);
    isListnrShow = false;
    return [myRadioEpisode, isListnrShow];
  }
  isListnrShow = true;
  return [listnrEpisode?.episode, isListnrShow];
}

async function getLatestEpisode(showSlug) {
  const result = await listnrApi.getLatestEpisode(showSlug);

  return get(result, 'show.episodes.items[0]', {});
}

const api = {
  getShow,
  getEpisode,
  getLatestEpisode,
};

export default api;

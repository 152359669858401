export const Actions = {
  ON_CAN_PLAY: 'ON_CAN_PLAY',
  ON_DURATION_CHANGED: 'ON_DURATION_CHANGED',
  ON_END: 'ON_END',
  ON_LOAD_ERROR: 'ON_LOAD_ERROR',
  ON_PAUSE: 'ON_PAUSE',
  ON_PLAY: 'ON_PLAY',
  ON_PLAY_ERROR: 'ON_PLAY_ERROR',
  ON_PLAYING: 'ON_PLAYING',
  ON_RATE_CHANGE: 'ON_RATE_CHANGE',
  ON_SEEK: 'ON_SEEK',
  ON_STOP: 'ON_STOP',
  ON_TIME_UPDATE: 'ON_TIME_UPDATE',
  ON_SET_NAME: 'ON_SET_NAME',
  ON_SET_EPISODE_ID: 'ON_SET_EPISODE_ID',
  ON_SET_AUDIO_URL: 'ON_SET_AUDIO_URL',
  START_LOAD: 'START_LOAD',
};

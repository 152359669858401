import { lazy, useEffect, useState } from 'react';
import { useAudioPlayer } from '../../../hooks/useAudioPlayer';
import useOmnyConsumptionAnalytics from '../../../hooks/useOmnyConsumptionAnalytics';
import usePrevious from '../../../hooks/usePrevious';
import { IFRAME_MESSAGE_TYPES } from '../../../utilities/helpers/iframeMessageTypes';
import Import from '../Import';
import NoContent from '../NoContent';
import api from '../../../utilities/api';

const SingleClipPlayerMobile = lazy(() => import('../../mobile/SingleClipPlayerMobile'));
const SingleClipPlayerTablet = lazy(() => import('../../tablet/SingleClipPlayerTablet'));
const SingleClipPlayerDesktop = lazy(() => import('../../desktop/SingleClipPlayerDesktop'));

const SingleClipPlayer = ({ clipId }) => {
  const [error, setError] = useState(false);
  const [clip, setClip] = useState({});
  const [isListnrShow, setListnrShow] = useState(null);
  const { setAudioSrc, pause, setName, position, playing, episodeId } = useAudioPlayer();

  const prevCurrentTime = usePrevious(position);
  const prevAudioPlaying = usePrevious(playing);
  const prevEpisodeId = usePrevious(episodeId);

  useOmnyConsumptionAnalytics({
    prevCurrentTime,
    currentTime: position,
    prevEpisodeId,
    episodeId,
    prevAudioPlaying,
    audioPlaying: playing,
    isListnrShow,
  });

  useEffect(() => {
    // iFrame message received from MyRadio
    window.addEventListener('message', handleIframeMessage, false);
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [episode, isListnrShow] = await api.getEpisode(clipId);
        setClip(episode);
        setListnrShow(isListnrShow);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setError(true);
      }
    };
    fetchData();
  }, [clipId]);

  useEffect(() => {
    if (clip && clip.audioUrl) {
      setAudioSrc(clip.audioUrl);
    }
  }, [clip, setAudioSrc]);

  const handleIframeMessage = (event) => {
    if (event.data?.type === IFRAME_MESSAGE_TYPES.registerNewPlayer) {
      setName(event?.data?.name);
    }
    if (event.data?.type === IFRAME_MESSAGE_TYPES.pausePlayer) {
      pause();
    }
  };

  if (error) {
    return <NoContent singleClip />;
  }

  return (
    <Import
      mobile={<SingleClipPlayerMobile clip={clip} />}
      tablet={<SingleClipPlayerTablet clip={clip} />}
      desktop={<SingleClipPlayerDesktop clip={clip} />}
    />
  );
};

export default SingleClipPlayer;

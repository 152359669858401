const padZero = (digit) => `${digit < 10 ? '0' : ''}${digit}`;

export function formatSecondsToTime(numSeconds) {
  const prefix = numSeconds < 0 ? '-' : '';
  const absNumSeconds = Math.abs(numSeconds);

  const hours = Math.floor(absNumSeconds / 3600);
  const minutes = Math.floor((absNumSeconds % 3600) / 60);
  const seconds = Math.floor(absNumSeconds) % 60;

  return hours > 0
    ? `${prefix}${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`
    : `${prefix}${padZero(minutes)}:${padZero(seconds)}`;
}

// formats UTC to dd/mm/yyyy
export function formatDate(d) {
  if (!d) {
    return '';
  }
  const date = new Date(d);
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '/' + mm + '/' + yyyy;
}

function padNumberWithZero(number) {
  if (number > 99) {
    return number;
  }
  return `0${number}`.slice(-2);
}

export function composeEpisodeHeader(season, episode, publishedUtc) {
  if (season && episode && publishedUtc) {
    return `S${padNumberWithZero(season)} - ${padNumberWithZero(episode)}      ${formatDate(publishedUtc)}`;
  }
  if (!publishedUtc) {
    return '';
  }
  return formatDate(publishedUtc);
}

export function addOmnyCustomSource(source) {
  if (!source) {
    return '';
  }
  const url = new URL(source);
  url.searchParams.append('utm_source', 'CustomPlayer6');

  return url.href;
}

export function addTritonCustomSource(source) {
  if (!source) {
    return '';
  }

  const url = new URL(source);
  url.searchParams.append('dist', 'listnr-embed');

  return url.href;
}

export function changePlaybackRate(currentRate) {
  const rates = [1, 1.5, 2];

  const index = rates.indexOf(currentRate);
  if (index === rates.length - 1) {
    return rates[0];
  }
  return rates[index + 1];
}

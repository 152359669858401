import './style.css';
import { ReactComponent as LogoIcon } from './logo.svg';

const ListnrLink = ({ small = false }) => {
  const size = small ? { width: 76, height: 14 } : {};

  return (
    <a href={process.env.REACT_APP_LISTNR_WEBSITE_URL} rel="noreferrer" target="_blank" className="footer-link">
      <LogoIcon className="logo" style={size} />
    </a>
  );
};

export default ListnrLink;

import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'js-sha256';
import {
  ApolloClient,
  //   ApolloLink,
  HttpLink,
  InMemoryCache,
  // ,NormalizedCacheObject
} from '@apollo/client';

const FETCH_POLICY = 'cache-and-network';
const API = process.env.REACT_APP_LISTNR_API;
const APOLLO_CLIENT_NAME = 'listnr-embeddedplayer';
const APOLLO_CLIENT_VERSION = '1.0';

// const httpLink = new HttpLink({ uri: API, useGETForQueries: false });

export const getApolloClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: persistedQueryLink,
    name: APOLLO_CLIENT_NAME,
    version: APOLLO_CLIENT_VERSION,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: FETCH_POLICY,
      },
    },
  });
};

const persistedQueryLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true }).concat(
  new HttpLink({ uri: API, useGETForQueries: true, fetchOptions: {} })
);

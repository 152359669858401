import { Actions } from '../action';

export const initialState = {
  audioUrl: null,
  duration: 0,
  firstLoad: true,
  ended: false,
  episodeId: null,
  error: null,
  loading: false,
  name: '',
  playbackRate: 1,
  playing: false,
  position: 0,
  ready: false,
  stopped: true,
};

export function reducer(state, action) {
  switch (action.type) {
    case Actions.ON_SET_NAME:
      return {
        ...state,
        name: action.name,
      };
    case Actions.ON_SET_AUDIO_URL:
      return {
        ...state,
        audioUrl: action.audioUrl,
        firstLoad: true,
      };
    case Actions.ON_SET_EPISODE_ID:
      return {
        ...state,
        episodeId: action.id,
      };
    case Actions.START_LOAD:
      return {
        ...state,
        loading: true,
        stopped: true,
        playing: false,
        ready: false,
        error: null,
        duration: 0,
      };
    case Actions.ON_CAN_PLAY:
      return {
        ...state,
        loading: false,
        ended: false,
        ready: true,
      };
    case Actions.ON_PLAY:
      return {
        ...state,
        playing: true,
        firstLoad: false,
        loading: false,
        ended: false,
        stopped: false,
      };
    case Actions.ON_STOP:
      return {
        ...state,
        stopped: true,
        playing: false,
      };
    case Actions.ON_SEEK:
      return {
        ...state,
        stopped: false,
        ended: false,
        playing: false,
        loading: true,
      };
    case Actions.ON_END:
      return {
        ...state,
        loading: false,
        stopped: true,
        playing: false,
        ended: true,
      };
    case Actions.ON_PAUSE:
      return {
        ...state,
        playing: false,
      };
    case Actions.ON_PLAY_ERROR:
      return {
        ...state,
        playing: false,
        loading: false,
        stopped: false,
        error: action.error,
      };
    case Actions.ON_LOAD_ERROR:
      return {
        ...state,
        playing: false,
        stopped: true,
        loading: false,
        error: action.error,
      };
    case Actions.ON_RATE_CHANGE:
      return {
        ...state,
        playbackRate: action.playbackRate,
      };
    case Actions.ON_DURATION_CHANGED:
      return {
        ...state,
        duration: action.duration,
      };
    case Actions.ON_TIME_UPDATE:
      return {
        ...state,
        position: action.position,
      };
    case Actions.ON_PLAYING:
      return {
        ...state,
        playing: true,
        firstLoad: false,
        loading: false,
        ended: false,
        stopped: false,
      };
    default:
      return state;
  }
}

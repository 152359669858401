import { fetchAPI } from '../../apollo/index';

export const GET_EPISODES = `
   query GetEpisodes($slug: String!, $pageNo: Int, $orderBy: String, $size: Int=10000) {
     show(where: { slugOrId: $slug }) {
      id
      slug
      name
      images {
        banner { url }
      }
      episodes(page: { size: $size, order: $orderBy, number: $pageNo }) {
        totalItems
        currentPage
        items {
          id
          title
          slug
          description
          imageUrl
          season
          episode
          contentRating
          durationSeconds
          publishedUtc
        }
      }
    }
  }
`;

export const GET_SHOW = `
  query GetOneShow($id: String!) {
     show(where: { slugOrId: $id }) {
      id
      slug
      name
      showType
      contentType
      rssFeedUrl
      description
      images {
        square {
          url
        }
      }
      episodes(page: { size: 5 } ) {
          totalItems
          currentPage
          items {
            id
            title
            slug
            audioUrl
            imageUrl
            description
            durationSeconds
            publishedUtc
            episode
            season
          }
      }
   }
 }
`;

export const GET_EPISODE = `
  query GetEpisode($id: String) {
     episode(where: { slugOrId: $id }) {
      id
      title
      slug
      audioUrl
      imageUrl
      description
      durationSeconds
      publishedUtc
      show {
        name
      }
    }
  }
`;

export const GET_LATEST_EPISODE = `
  query GetLatestEpisode($showSlug: String) {
    show(where: { slugOrId: $showSlug }) {
      episodes (page: { order: "DESC", size: 1 }) {
        items {
          slug
          audioUrl
        }
      }
    }
  }
`;

async function getEpisode(episodeId) {
  try {
    return fetchAPI(GET_EPISODE, { id: episodeId });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('ERROR => getEpisode', error);
    return error;
  }
}

async function getShow(playlistId) {
  try {
    const result = await fetchAPI(GET_SHOW, { id: playlistId });
    const extendableResult = JSON.parse(JSON.stringify(result));
    return extendableResult;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('ERROR => getShow', error);
    return error;
  }
}

async function getLatestEpisode(showSlug) {
  try {
    const result = await fetchAPI(GET_LATEST_EPISODE, { showSlug });

    return JSON.parse(JSON.stringify(result));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('ERROR => getLatestEpisode', error);

    return error;
  }
}

const listnrApi = {
  getShow,
  getEpisode,
  getLatestEpisode,
};

export default listnrApi;
